import React, { Component } from 'react';
import { connect } from "react-redux";
import { baseStoreMapping } from "../store/mappings";
import Category from "../components/Category";
import { loadCategories } from "../store/actions/public";

class CategoriesContainer extends Component {

    componentDidMount() {
        this.props.loadCategories()
    }

    render() {
        const categories = this.props.public.categories.map(category => <Category key={category.id}
                                                                                  category={category} />);
        return (
            <div className="widget">
                <h3><img src="/static/shop/images/ebookicon.svg" className="ebook-icon" alt="Ebooki"
                         title="Ebooki"/> EBOOKI</h3>
                <div className="list-group list-group-brand list-group-accordion ebook-menu">
                    {categories}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCategories: () => dispatch(loadCategories())
    }
};

export default connect(baseStoreMapping, mapDispatchToProps)(CategoriesContainer)