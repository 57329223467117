import api from "./axios-seb";

export function getComments() {
  return api.get('/comments/');
}

export function getCategories() {
  return api.get('/categories/');
}

export function getCycles() {
  return api.get('/recommended-cycles/');
}