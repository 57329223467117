import createSagaMiddleware from 'redux-saga';
import reducers from './reducers'
import { applyMiddleware, createStore } from 'redux'
import sagas from '../middlewares/sagas'
import { composeWithDevTools } from 'redux-devtools-extension'

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(
    reducers,
    //composeWithDevTools(
    applyMiddleware(sagaMiddleWare)
    //)
);

sagaMiddleWare.run(sagas);