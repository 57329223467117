import React, { Component } from 'react';
import Aux from "../containers/Aux";

export default class Cycle extends Component {

    render = () => {
        const cycle = this.props.cycle;
        return (
            <Aux>
                <a className="list-group-item item-cycle" href={cycle.absolute_url}
                   title={`Ebooki cyklu ${cycle.name}`}>
                    <span>{cycle.name}</span>
                    <span className="pull-right"><small>{cycle.cycle_books_count}</small></span>
                </a>
            </Aux>
        )
    }
}