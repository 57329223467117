import { createActions } from 'redux-actions'
import { FETCH_CATEGORIES, FETCH_COMMENTS, LOAD_CATEGORIES, LOAD_COMMENTS, LOAD_CYCLES, FETCH_CYCLES } from "./actionTypes";

export const { fetchComments, loadComments, fetchCategories, loadCategories, loadCycles, fetchCycles } = createActions({
    [ FETCH_COMMENTS ]: null,
    [ LOAD_COMMENTS ]: null,
    [ FETCH_CATEGORIES ]: null,
    [ LOAD_CATEGORIES ]: null,
    [ LOAD_CYCLES ]: null,
    [ FETCH_CYCLES ]: null,
})