import axios from 'axios';

axios.defaults.validateStatus = function (status) {
    return status >= 200 && status < 300;
}

let privateBaseURL = '';

if (process.env.NODE_ENV === 'development') {
  privateBaseURL = 'http://seb.local:8088/'
} else {
  privateBaseURL = '/'
}

const params = {
    baseURL: privateBaseURL +'api/'
};

let api = axios.create(params);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const baseURL = privateBaseURL;

export default api;