import React, { Component } from 'react';

export default class Comment extends Component {
    render = () => {

        const comment = this.props.comment;
        const author = comment.author && comment.author.first_name ? `${comment.author.first_name} ${comment.author.last_name}` : comment.anonymous_author;
        const content = comment.content.split(" ").splice(0, 20).join(" ");
        return (
            <li>
                <div className="testimonial-details">
                    <header><a className="item-comment"
                        href={this.props.comment.content_object.cached_full_url}>{comment.content_object.title}</a>
                    </header>
                    {content}
                </div>
                <figure className="clearfix comment-avatar">
                    <figcaption>
                        <span>{author}</span><br/>
                    </figcaption>
                </figure>
            </li>
        )
    }
}