import React, { Component } from 'react';
import { connect } from "react-redux";
import { loadComments } from "../store/actions/public";
import { baseStoreMapping } from "../store/mappings";
import Comment from "../components/Comment";

class CommentsContainer extends Component {

    componentDidMount() {
        this.props.loadComments()
    }

    render() {
        const comments = this.props.public.comments.map(comment => <Comment key={comment.id} comment={comment}/>)
        return (
            <div>
                <h3><i className="fa fa-comment-o" /> KOMENTARZE</h3>
                <ul className="testimonials-list clearfix">
                    {comments}
                </ul>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadComments: () => dispatch(loadComments())
    }
};

export default connect(baseStoreMapping, mapDispatchToProps)(CommentsContainer)

