import React, { Component } from 'react';
import { connect } from "react-redux";
import { baseStoreMapping } from "../store/mappings";
import Cycle from "../components/Cycle";
import { loadCycles } from "../store/actions/public";

class CyclesContainer extends Component {

    componentDidMount() {
        this.props.loadCycles()
    }

    render() {
        const cycles = this.props.public.cycles.map(cycle => <Cycle key={cycle.id}
                                                                    cycle={cycle} />);
        if (!cycles.length) return null;

        return (
            <div className="widget">
                <h3><i className="fa fa-pencil-square-o"/> Cykle literackie</h3>
                <div className="list-group list-group-brand list-group-accordion ebook-menu">
                    { cycles }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCycles: () => dispatch(loadCycles())
    }
};

export default connect(baseStoreMapping, mapDispatchToProps)(CyclesContainer)