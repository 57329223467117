import { call, put, takeLatest } from 'redux-saga/effects'
import * as API from '../api/client'
import {
    CATEGORIES_FETCH_FAILED,
    COMMENT_FETCH_FAILED, CYCLES_FETCH_FAILED,
    LOAD_CATEGORIES,
    LOAD_COMMENTS, LOAD_CYCLES
} from "../store/actions/actionTypes";
import { fetchCategories, fetchComments, fetchCycles } from "../store/actions/public";

function* loadComments(action) {
    try {
        const response = yield call(API.getComments);
        yield put(fetchComments(response.data))
    } catch (e) {
        yield put({ type: COMMENT_FETCH_FAILED, message: e.message });
    }
}

function* loadCategories(action) {
    try {
        const response = yield call(API.getCategories);
        yield put(fetchCategories(response.data))
    } catch (e) {
        yield put({ type: CATEGORIES_FETCH_FAILED, message: e.message });
    }
}

function* loadCycles(action) {
    try {
        const response = yield call(API.getCycles);
        yield put(fetchCycles(response.data))
    } catch (e) {
        yield put({ type: CYCLES_FETCH_FAILED, message: e.message });
    }
}

function* serviceSaga() {
    yield takeLatest(LOAD_COMMENTS, loadComments);
    yield takeLatest(LOAD_CATEGORIES, loadCategories);
    yield takeLatest(LOAD_CYCLES, loadCycles);
}

export default serviceSaga;