import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import CommentsContainer from "./containers/CommentsContainer";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import CategoriesContainer from "./containers/CategoriesContainer";
import WebFont from 'webfontloader';
import CyclesContainer from "./containers/CyclesContainer";

const comments_element = document.getElementById('comments_list');
const categories_element = document.getElementById('categories_list');
const cycles_element = document.getElementById('cycles_list');

WebFont.load({
    google: {
        families: ['PT Sans:400,700,400italic,700italic', 'PT+Gudea:400,700,400italic', 'PT+Oswald:400,700,300']
    }
});

if (comments_element) {
    const comments = (
        <Provider store={store}>
            <CommentsContainer />
        </Provider>
    );
    ReactDOM.render(comments, comments_element);
}

if (categories_element) {
    const categories = (
        <Provider store={store}>
            <CategoriesContainer />
        </Provider>
    );
    ReactDOM.render(categories, categories_element);
}

if (cycles_element) {
    const cycles = (
        <Provider store={store}>
            <CyclesContainer />
        </Provider>
    );
    ReactDOM.render(cycles, cycles_element);
}

serviceWorker.unregister();