import React, { Component } from 'react';
import Aux from "../containers/Aux";

export default class Category extends Component {

    styleOpened = {
        display: "block"
    };

    constructor( props ) {
        super(props);
        const selected = window.location.href.split('\/').slice(-2, -1).pop();
        const cat = this.props.category.children.filter(subcat => subcat.slug === selected);
        this.state = { opened: (cat.length || this.props.category.slug === selected) };
    }

    toggle = (event) => {
        this.setState({ opened: !this.state.opened });
        event.preventDefault();
    };

    render = () => {
        const category = this.props.category;
        const children = category.children.map(subcat => (
            <a key={subcat.id} href={subcat.absolute_url} className="list-group-item submenu-item item-category"
               title={`Ebooki z kategorii ${subcat.name}`}>
                <span className="sub-name">{subcat.name}</span>
                <span className="pull-right"><small>{subcat.ebooks}</small></span>
            </a>
        ));

        return (
            <Aux>
                <a className="list-group-item item-category" href={category.absolute_url}
                   title={`Ebooki z kategorii ${category.name}`} onClick={children.length ? this.toggle : null}>
                    <span>{category.name}</span>
                    <span className="pull-right"><small>{category.ebooks}</small></span>
                    <img src='/static/shop/images/menu_arrow.svg' className="menu-arrow"/>
                </a>
                <div className="children" style={this.state.opened ? this.styleOpened : {}}>
                    {children}
                </div>
            </Aux>
        )
    }
}