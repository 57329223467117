import { FETCH_CATEGORIES, FETCH_COMMENTS, FETCH_CYCLES } from "../actions/actionTypes";

const initialState = {
    comments: [],
    categories: [],
    cycles: []
};

export default function publicObjects(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMMENTS:
            return {
                ...state,
                comments: action.payload
            };
        case FETCH_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case FETCH_CYCLES:
            return {
                ...state,
                cycles: action.payload
            };
        default:
            return state;
    }
}